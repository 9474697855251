<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="goBack"
      fixed
      placeholder
    />
  </div>
</template>
  
  <script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
::v-deep .van-nav-bar {
  z-index: 2;
}
</style>
