<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      signData: {},
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100vh;
}
img {
  max-width: 100%;
}
a {
  color: inherit;
}
</style>
