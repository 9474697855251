import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "cardid",
  },
  {
    path: "/cardid",
    name: "cardid",
    component: () => import("@/views/card/index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/cardid/:id",
    name: "cardid",
    component: () => import("@/views/card/index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/tpl1/cardid/:id",
    name: "cardid",
    component: () => import("@/views/card/index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/tpl2/cardid/:id",
    name: "cardid",
    component: () => import("@/views/card/index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/goods",
    name: "goods",
    component: () => import("@/views/card/goods.vue"),
    meta: {
      title: "礼品卡查询",
    },
  },
  {
    path: "/card",
    name: "card",
    component: () => import("@/views/card/card.vue"),
    meta: {
      title: "储值卡",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
